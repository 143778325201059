import React from 'react'
//import { Container } from '@material-ui/core';

const NotFoundPage = () => {
    return (
        <>
            Pagina 404
        </>
    )
}

export default NotFoundPage
