// react
import React, { useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
// context PreferenceId
import { PreferenceIdContext } from '../../contexts/PreferenceIdContext';
// material-ui
import {
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Grid,
	Typography,
	TextField,
	Button,
} from '@material-ui/core';
//styles
import { makeStyles } from '@material-ui/core/styles';
// axios
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(1, 0, 2),
	},
	formControl: {
		width: '100%',
	},
	selectEmpty: {
		marginTop: theme.spacing(0),
	},
	tituloRegalar: {
		marginBottom: theme.spacing(4),
	},
}));

const SeccionRegalos = ({
	zonas,
	tipoEvento,
	anfitrionUno,
	anfitrionDos,
	email,
}) => {
	const classes = useStyles();
	const history = useHistory();
	const { id } = useParams(); // id de producto

	//context PreferenceId
	const {
		setPreferenceId,
		monto,
		setMonto,
		zonassss,
		setZonassss,
		padrinos,
		setPadrinos,
		mensajeFelicitacion,
		setMensajeFelicitacion,
	} = useContext(PreferenceIdContext);

	const obtenerPreferencia = async () => {
		// Obtener PreferenceID
		try {
			const order = await axios.post(
				process.env.REACT_APP_API_CREATE_PREFERENCE,
				{
					monto,
					zonassss,
					padrinos,
					mensajeFelicitacion,
					id,
					tipoEvento,
					anfitrionUno,
					anfitrionDos,
					email,
				}
			);
			console.log('preferenceID: ', order.data.preferenceId);
			setPreferenceId(order.data.preferenceId);
			history.push(`/aportar/${id}`);
		} catch (error) {
			console.log(error);
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		obtenerPreferencia();
	};

	return (
		<>
			<Typography
				variant='h6'
				gutterBottom
				align='center'
				className={classes.tituloRegalar}
			>
				Aportaciones:
			</Typography>
			<form className={classes.form} onSubmit={handleSubmit}>
				<Grid container spacing={4}>
					<Grid item xs={12} md={6}>
						<FormControl
							required
							variant='outlined'
							className={classes.formControl}
						>
							<InputLabel id='monto-label'>Monto</InputLabel>
							<Select
								labelId='monto-label'
								id='monto'
								value={monto}
								onChange={(e) => setMonto(Number(e.target.value))}
								label='Monto'
								className={classes.selectEmpty}
							>
								<MenuItem value='500'>$ 500</MenuItem>
								<MenuItem value='1000'>$ 1,000</MenuItem>
								<MenuItem value='1500'>$ 1,500</MenuItem>
								<MenuItem value='2000'>$ 2,000</MenuItem>
								<MenuItem value='2500'>$ 2,500</MenuItem>
								<MenuItem value='3000'>$ 3,000</MenuItem>
								<MenuItem value='3500'>$ 3,500</MenuItem>
								<MenuItem value='4000'>$ 4,000</MenuItem>
								<MenuItem value='4500'>$ 4,500</MenuItem>
								<MenuItem value='5000'>$ 5,000</MenuItem>
								<MenuItem value='5500'>$ 5,500</MenuItem>
								<MenuItem value='6000'>$ 6,000</MenuItem>
								<MenuItem value='6500'>$ 6,500</MenuItem>
								<MenuItem value='7000'>$ 7,000</MenuItem>
								<MenuItem value='7500'>$ 7,500</MenuItem>
								<MenuItem value='8000'>$ 8,000</MenuItem>
								<MenuItem value='8500'>$ 8,500</MenuItem>
								<MenuItem value='9000'>$ 9,000</MenuItem>
								<MenuItem value='9500'>$ 9,500</MenuItem>
								<MenuItem value='10000'>$ 10,000</MenuItem>
							</Select>
						</FormControl>
					</Grid>

					<Grid item xs={12} md={6}>
						<FormControl
							required
							variant='outlined'
							className={classes.formControl}
						>
							<InputLabel id='tipoEvento-label'>Aportar para</InputLabel>
							<Select
								labelId='tipoEvento-label'
								id='tipoEvento'
								value={zonassss}
								onChange={(e) => setZonassss(e.target.value)}
								label='Tipo de Evento'
								className={classes.selectEmpty}
							>
								{zonas &&
									zonas.map((zona) => (
										<MenuItem value={zona.label} key={zona.key}>
											{zona.label}
										</MenuItem>
									))}
							</Select>
						</FormControl>
					</Grid>

					<Grid item xs={12}>
						<TextField
							variant='outlined'
							margin='normal'
							required
							fullWidth
							name='padrinos'
							label='Quien/ Quienes aportan'
							type='text'
							id='padrinos'
							autoComplete='off'
							value={padrinos}
							onChange={(e) => setPadrinos(e.target.value)}
						/>
					</Grid>

					<Grid item xs={12}>
						<TextField
							id='mensajeFelicitacion'
							variant='outlined'
							name='mensajeFelicitacion'
							label='Mensaje de felicitación'
							fullWidth
							multiline
							rows={6}
							value={mensajeFelicitacion}
							onChange={(e) => setMensajeFelicitacion(e.target.value)}
						/>
					</Grid>

					<Grid item xs={4}>
						<Button
							type='submit'
							fullWidth
							variant='contained'
							color='primary'
							id='btnAportar'
						>
							Continuar
						</Button>
					</Grid>
				</Grid>
			</form>
		</>
	);
};

export default SeccionRegalos;
