//react
import React , { useContext } from 'react';
import { useHistory } from 'react-router-dom'
//material-ui
import { Grid, Card, CardContent, Typography, Button } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import CloseIcon from '@material-ui/icons/Close';
//styles
import { makeStyles } from '@material-ui/core/styles';

import { MesaUserContext } from '../../contexts/MesaUserContext' 

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 275,
        margin: theme.spacing(4,0,4,0)
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    mesaID: {
        marginTop: theme.spacing(2),
        color: 'primary'
    }
}));

export default function CardRegalosAdmin ({ mesas, onDeleteLink }) {
    const classes = useStyles();

    const { setMesaID } = useContext(MesaUserContext);
    const history = useHistory();

    const onEditLink = (id) => {
        setMesaID(id);
        history.push('/editar-mesa')
    }

    const verDetallesMesa = (id) => {
        setMesaID(id);
        history.push('/detalles-mesa')
    }

    return (
        <>
            { mesas && mesas.map(mesa => (
                <Card className={classes.root} key={mesa.id}>
                    <CardContent>
                        <Grid container>
                            <Grid item xs={10}>
                                <Grid container >
                                    <Grid item xs={12} >
                                        
                                        <Grid container>
                                            <Grid item xs={12} md={4}>
                                                <Typography>
                                                    Mesa de Regalo: {mesa.anfitrionUno} & {mesa.anfitrionDos}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <Typography>
                                                    Fecha: {mesa.fecha}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <Typography>
                                                    Tipo de evento: {mesa.tipoEvento}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        
                                    </Grid>
                                    <Grid item xs={6} md={3}>
                                        <Typography variant="h4" align="center">
                                            {mesa.regalos}
                                        </Typography>
                                        <Typography variant="body2" align="center">
                                            Regalos recibidos
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} md={3}>
                                        <Typography variant="h4" align="center">
                                            $ {mesa.cantidad}
                                        </Typography>
                                        <Typography variant="body2" align="center">
                                            Total recibido
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} md={3}>
                                        <Typography variant="h6">
                                            Zonas: 
                                        </Typography>
                                        <Typography variant="body1">
                                            {mesa.zonas && mesa.zonas.map(zona => (
                                                <span key={zona.key} >{zona.label + ', '}</span>
                                            ))}
                                        </Typography>
                                        
                                    </Grid>
                                </Grid>
                                <Grid container className={classes.mesaID}>
                                    <Typography variant='body2' color='primary'>
                                        {mesa.id}
                                    </Typography>
                                </Grid>
                                
                            </Grid>
                            <Grid item xs={2}>
                                <Grid container direction="column" alignItems="flex-end">
                                    <Grid item>
                                        <CreateIcon onClick={() => onEditLink(mesa.id)}/>
                                        <CloseIcon color="error" onClick={() => onDeleteLink(mesa.id)}/>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="outlined" onClick={() => verDetallesMesa(mesa.id)}>
                                            ver detalles
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        
                    </CardContent>
                </Card>
            )) }
        </>
    );
}