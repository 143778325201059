//react
import React, { useState } from 'react';
//material-ui
import { Grid,  TextField, Button } from '@material-ui/core';
//styles
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    buscar: {
        backgroundColor: '#FFFFFF',
    },
    btnBuscar : {
        
    }
}))

export default function BuscarMesa () {
    const classes = useStyles();

    const [selectedDate, setSelectedDate] = useState();
    const [anfitriones, setAnfitriones] = useState();

    return (
        <form noValidate autoComplete="off">
            <Grid container spacing={4}>
                <Grid item xs={12} sm={8} md={9}>
                    <TextField
                        className={classes.buscar}
                        required
                        label="Anfitriones del Evento"
                        id="anfitriones"
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <TextField
                        className={classes.buscar}
                        id="date"
                        required
                        fullWidth
                        variant="outlined"
                        label="Fecha del evento"
                        type="date"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid container justify='center' item xs={12}>
                    <Button variant="outlined" color="primary">
                        Buscar
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
}