//react
import React from 'react';
//material-ui
import { Grid, Card, CardContent, Typography } from '@material-ui/core';
//styles
import { makeStyles } from '@material-ui/core/styles';



const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 275,
        margin: theme.spacing(4,0,4,0)
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
}));



export default function CardResumenAdmin ({ numMesas, total, tipoMesa }) {
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <CardContent>
                <Grid container>
                    <Grid item xs={12}>
                        <Grid container alignItems='center'>
                            <Grid item xs={6} md={4} >
                                <Typography variant="h4" align="center" gutterBottom>
                                    { numMesas }
                                </Typography>
                                <Typography variant="h6" align="center">
                                    Mesas de regalo activas
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Typography variant="h4" align="center" gutterBottom>
                                    $ { total }
                                </Typography>
                                <Typography variant="h6" align="center">
                                    Total ingresado
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Typography variant="h6" gutterBottom>
                                    Tipo de mesas
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    Boda: { tipoMesa.Boda }
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    Bautizo: { tipoMesa.Bautizo }
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    Primera comunión: { tipoMesa.PrimeraComunion }
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    Open House: { tipoMesa.OpenHouse }
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    Cumpleaños: { tipoMesa.Cumple }
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    Otro: { tipoMesa.Otro }
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}