//react
import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
//material-ui
import { Typography, Container } from '@material-ui/core'
//components
import CardRegalosUser from '../components/user/CardRegalosUser'
import ListaRegalos from '../components/user/ListaRegalos'
//firebase
import { db } from '../firebase/firebaseConfig'
// styles
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    resumenSection: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
    }
}))

const RegalosPageUser = () => {

    const { user } = useContext(AuthContext);

    const classes = useStyles();

    const [mesa, setMesa] = useState({});
    const [aportaciones, setAportaciones] = useState([])
    const [padrinos, setPadrinos] = useState([])

    const getMesa = async (id) => {
        try {
            const data = await db.collection('mesas').doc(id).get()
            const info = {...data.data()}
            setMesa(info)
            setAportaciones(info.aportaciones)
            setPadrinos(info.padrinos)
            
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getMesa(user.uid);
    }, [])

    return (
        <>
            <Container maxWidth="lg">
                <Typography variant='h4' gutterBottom>
                    Administrador de regalos
                </Typography>
                <Container className={classes.resumenSection}>
                    <CardRegalosUser mesa={mesa}/>
                </Container>
                <Typography variant='h5' gutterBottom>
                    Desglose
                </Typography>
                <ListaRegalos {...{ aportaciones, padrinos }}/>
            </Container>
        </>
    )
}

export default RegalosPageUser
