//react
import React from 'react';
//material-ui
import { Typography, Grid, Card, CardContent } from '@material-ui/core';
//styles
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

export default function CardRegalos({mesa}) {
    const classes = useStyles();

    return mesa && (
        <Card className={classes.root}>
            <CardContent>
                <Grid container>
                    <Grid item xs={6} md={3}>
                        <Typography variant="h4" align="center">
                            {mesa.regalos}
                        </Typography>
                        <Typography variant="body2" align="center">
                            Regalos recibidos
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Typography variant="h4" align="center">
                            $ {mesa.cantidad}
                        </Typography>
                        <Typography variant="body2" align="center">
                            Total recibido
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <Typography variant="h6">
                            Zonas: 
                        </Typography>
                        <Typography variant="body1">
                            {mesa.zonas && mesa.zonas.map(zona => (
                                <span key={zona.key} >{zona.label + ', '}</span>
                            ))}
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}