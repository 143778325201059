//react
import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
//material-ui
import { Container, Typography, Button, TextField, Grid, FormControl, Select, MenuItem, InputLabel } from '@material-ui/core';
//components
import ZonasMesas from '../components/admin/ZonasMesa';
import ImgProyecto from '../components/admin/ImgProyecto';
import ImgPortada from '../components/admin/ImgPortada';
//styles
import { makeStyles } from '@material-ui/core/styles';
//firebase
import { db } from '../firebase/firebaseConfig'
// context mesa
import { MesaUserContext } from '../contexts/MesaUserContext' 

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    formControl: {
        width: '100%',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const EditarMesaRegalosAdmin = () => {

    const classes = useStyles();

    const history = useHistory();

    //state mesa
    const [anfitrionUno, setAnfitrionUno] = useState('')
    const [anfitrionDos, setAnfitrionDos] = useState('')
    const [fecha, setFecha] = useState('')
    const [lugar, setLugar] = useState('')
    const [tipoEvento, setTipoEvento] = useState('')

    //zonas
    const [zonas, setZonas] = useState([])

    //video
    const [videoLink, setVideoLink] = useState('')
    
    //Portada
    const [portadaLink, setPortadaLink] = useState('')
    
    //Proyecto
    const [proyectoLink, setProyectoLink] = useState([])

    //MesaID
    const { mesaID } = useContext(MesaUserContext);

    const getMesa = async (mesaID) => {
        const data = await db.collection('mesas').doc(mesaID).get()
        
        const info = {...data.data()}

        setAnfitrionUno(info.anfitrionUno)
        setAnfitrionDos(info.anfitrionDos)
        setTipoEvento(info.tipoEvento)
        setFecha(info.fecha)
        setLugar(info.lugar)
        setZonas(info.zonas)
        setVideoLink(info.video)
        setPortadaLink(info.imgPortada)
        setProyectoLink(info.imgProyecto)
    } 

    useEffect(() => {
        getMesa(mesaID);
    }, [mesaID])

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            
            await db.collection('mesas').doc(mesaID).update({
                
                anfitrionUno,
                anfitrionDos,
                tipoEvento,
                fecha,
                lugar,
                zonas,
                video: videoLink
                
            })
            history.push('/mesas-regalos')

        } catch (error) {
            console.error(error)
        }
    }

    return (
        <>
            <Container maxWidth='md'>
                <Typography variant='h5' gutterBottom>
                    Editar Mesa de Regalo
                </Typography>

                <form className={classes.form} onSubmit={handleSubmit}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="anfitrionUno"
                                label="Anfitrión"
                                type="text"
                                id="anfitrionUno"
                                autoComplete="off"
                                value={anfitrionUno}
                                onChange={e => setAnfitrionUno(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="anfitrionDos"
                                label="Pareja"
                                type="text"
                                id="anfitrionDos"
                                autoComplete="off"
                                value={anfitrionDos}
                                onChange={e => setAnfitrionDos(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl required variant="outlined" className={classes.formControl}>
                                <InputLabel id="tipoEvento-label">Tipo de Evento</InputLabel>
                                <Select
                                    labelId="tipoEvento-label"
                                    id="tipoEvento"
                                    value={tipoEvento}
                                    onChange={e => setTipoEvento(e.target.value)}
                                    label="Tipo de Evento"
                                    className={classes.selectEmpty}
                                >
                                    <MenuItem value="Boda">Boda</MenuItem>
                                    <MenuItem value="Bautizo">Bautizo</MenuItem>
                                    <MenuItem value="PrimeraComunion">Primera comunión</MenuItem>
                                    <MenuItem value="OpenHouse">Open House</MenuItem>
                                    <MenuItem value="Cumple">Cumpleaños</MenuItem>
                                    <MenuItem value="Otro">Otro</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="date"
                                required
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                label="Fecha del evento"
                                type="date"
                                value={fecha}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={e => setFecha(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="lugarEvento"
                                label="Lugar del Evento"
                                type="text"
                                id="lugarEvento"
                                autoComplete="off"
                                value={lugar}
                                onChange={e => setLugar(e.target.value)}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography gutterBottom variant="h6">
                                Zonas: 
                            </Typography>
                            <ZonasMesas {...{ zonas, setZonas }}/>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography gutterBottom variant="h6">
                                Link video proyecto (Youtube): 
                            </Typography>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="video"
                                label="Link video"
                                type="text"
                                id="video"
                                autoComplete="off"
                                value={videoLink}
                                onChange={e => setVideoLink(e.target.value)}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography gutterBottom variant="h6">
                                    Imágenes: 
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <ImgPortada {...{ mesaID, portadaLink, setPortadaLink }} />
                        </Grid>

                        <Grid item xs={12}>
                            <ImgProyecto {...{ mesaID, proyectoLink, setProyectoLink }} />
                        </Grid>
                        
                        <Grid item xs={12} sm={8}>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                            >
                                Guardar
                            </Button>
                        </Grid>
                    </Grid>
                    
                </form>
            </Container>
            
        </>
    )
}

export default EditarMesaRegalosAdmin
