//react
import React from 'react';
//material-ui
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
//styles
import { withStyles, makeStyles } from '@material-ui/core/styles';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);


const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default function ListaRegalos({ aportaciones, padrinos }) {
  const classes = useStyles();


  const formatoFecha = (fecha) => {
    const date = new Date(fecha)
    const d = date.toDateString()
    
    return d
  }

  const quienAportacion = (preferenceId) => {

    let padrino=''

    if(padrinos) {
      const p = padrinos.find(id => id.preferenceId === preferenceId )
      if(p) {
        padrino = p
      }
    }
    
    return padrino.padrinos
  }


  const mensajeAportacion = (preferenceId) => {
    let mensaje=''

    if(padrinos) {
      const m = padrinos.find(id => id.preferenceId === preferenceId )

      if(m) {
        mensaje = m
      }
    }
    
    return mensaje.mensajeFelicitacion
  }

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Zona del proyecto: </StyledTableCell>
            <StyledTableCell align="right">monto</StyledTableCell>
            <StyledTableCell align="right">Quien</StyledTableCell>
            <StyledTableCell align="right">Mensaje</StyledTableCell>
            <StyledTableCell align="right">Fecha</StyledTableCell>
            <StyledTableCell align="right">Referencia</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {aportaciones && aportaciones.map((aportacion) => (
            
            <StyledTableRow key={aportacion.id_payment}>
              <StyledTableCell component="th" scope="row">
                {aportacion.zona}
              </StyledTableCell>

              <StyledTableCell align="right">$ {aportacion.monto}</StyledTableCell>

              <StyledTableCell align="right">{ quienAportacion(aportacion.preferenceId) }</StyledTableCell>

              <StyledTableCell align="right">{ mensajeAportacion(aportacion.preferenceId)}</StyledTableCell>

              <StyledTableCell align="right">{ formatoFecha(aportacion.fechaAportacion) }</StyledTableCell>

              <StyledTableCell align="right">{aportacion.id_payment}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}