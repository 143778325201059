//react
import React from 'react'
import { Link } from 'react-router-dom'
//material-ui
import { Container, Grid, Typography } from '@material-ui/core'
import PinterestIcon from '@material-ui/icons/Pinterest';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';

//styles
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    background: {
        backgroundColor: '#CFC1B5',
        minHeight: '240px'
    },
    logo: {
        width: '200px',
        height: '100%'
    },
    links: {
        color: '#FFFFFF',
        textDecoration: 'none'
    },
    social :{
        color: '#FFFFFF'
    }
}))

const Footer = () => {
    const classes = useStyles()

    return (
        <Grid container className={classes.background} alignItems="center">
            <Container >
                <Grid container spacing={4} alignItems="center">
                    <Grid container item xs={12} sm={4} justify="center">
                        <img src='/images/logoBlanco.png' alt='logo' className={classes.logo}/>
                    </Grid>
                    <Grid container item xs={12} sm={4} direction="column" justify="center"  alignItems="center">
                        <Typography variant="body1" gutterBottom>
                            <Link to={{ pathname: "https://studiomaisonco.com/aviso-privacidad/" }} className={classes.links} target="_blank">
                                Aviso de Privacidad
                            </Link>
                        </Typography>
                        <Typography variant="body1" gutterBottom>

                            <Link to={{ pathname: "https://studiomaisonco.com/terminos-y-condiciones/" }} className={classes.links} target="_blank">
                                Términos y condiciones
                            </Link>
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <Link to={{ pathname: "https://studiomaisonco.com/proyectos/" }} className={classes.links} target="_blank">
                                Proyectos
                            </Link>
                        </Typography>
                    </Grid>
                    <Grid container item xs={12} sm={4} direction="column" justify="center"  alignItems="center">
                        <Grid item>
                            <Typography variant="body1" className={classes.links}>
                                info@studiomaisonco.com
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Link to={{ pathname: "https://www.facebook.com/studio.maison.co" }} target="_blank">
                                <FacebookIcon className={classes.social}/>
                            </Link>
                            <Link to={{ pathname: "https://www.pinterest.com.mx/studiomaisonco/" }} target="_blank">
                                <PinterestIcon className={classes.social}/>
                            </Link>
                            <Link to={{ pathname: "https://www.instagram.com/studio.maison.co/" }} target="_blank">
                                <InstagramIcon className={classes.social}/>
                            </Link>
                        </Grid>
                        
                    </Grid>
                </Grid>
                
            </Container>
        </Grid>
    )
}

export default Footer
