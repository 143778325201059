import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/storage';
import 'firebase/firebase-firestore';

const firebase = app.initializeApp({
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
	measurementId: process.env.REACT_APP_FIREBASE_MESUREMENT_ID,
});

const auth = firebase.auth();
const functions = firebase.functions();
const storage = firebase.storage();
const db = firebase.firestore();

if (window.location.hostname === 'localhost') {
	functions.useEmulator('localhost', 5001);
	auth.useEmulator('http://localhost:9099');
	db.useEmulator('localhost', 8080);
	//storage.useEmulator('localhost', 9199);
}

export { auth, functions, storage, db };
