//react
import React, { useState, useRef } from 'react'
//material-ui
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import { Grid, Typography } from '@material-ui/core';
//styles
import { makeStyles } from '@material-ui/core/styles';
//firebase 
import { db, storage } from '../../firebase/firebaseConfig'

const useStyles = makeStyles((theme) => ({
    imgProyecto: {
        width: '25%',
        maxWidth: '25%',
        marginTop: theme.spacing(1),
    },
    root: {
        maxWidth: 345,
    },
    media: {
        height: 160,
        width: 160
    },
}));

const ImgProyecto = ({ mesaID, proyectoLink, setProyectoLink }) => {

    const [imgProyecto, setImgProyecto] = useState('')
    const [imgProyectoError, setImgProyectoError] = useState(null)

    const classes = useStyles();

    const types = ['image/png', 'image/jpeg'];

    const inputRef = useRef('');

    const imgProyectoHandler = (e) => {
        let selectedImg = e.target.files[0]
        console.log('onchangeSelectedimg: ', selectedImg)

        if(selectedImg && types.includes(selectedImg.type)) {
            setImgProyecto(selectedImg)
            setImgProyectoError('')
        } else {
            setImgProyecto('')
            setImgProyectoError('Solo imagenes png / jpg')
        }
    }

    const actualizarFotoProyecto = async (arr) => {
        try {
            await db.collection('mesas').doc(mesaID).update({
                imgProyecto: arr
            })
            setProyectoLink(arr)
        } catch (error) {
            console.error(error)
        }
    }

    const agregarImgProyecto = () => {
        let a = [...proyectoLink]

        //console.log('imagen: ', imgProyecto)
        //console.log('a antes push: ', a)

        if(imgProyecto) {
            setImgProyectoError('')
            const imgProyectoRef = storage.ref(`${mesaID}/proyecto/${imgProyecto.name}`)
            imgProyectoRef.put(imgProyecto).on('state_changed', snapshot => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log(progress);
            }, err => {
                setImgProyectoError(err)
            }, async () => {
                try {
                    const imgProyectoUrl = await imgProyectoRef.getDownloadURL();
                    //console.log(imgProyectoUrl);
                    a.push({key: Math.random()*1000, url: imgProyectoUrl })
                    //console.log('a despues push: ', a)
                    actualizarFotoProyecto(a);
                    
                } catch (error) {
                    console.log(error);
                }
            })
        } else {
            setImgProyectoError('Ningun archivo selecionado')
        }
        inputRef.current = null
        setImgProyecto('')
    }

    const handleDelete = async (d) => {
        const httpsReference = storage.refFromURL(d.url)
        let b = [...proyectoLink]
        //console.log('b antes de borrar: ' ,b)
        try {
            await httpsReference.delete()
            const c = b.filter( link => link.url !== d.url )
            //console.log('c despues de filtrar b: ' ,c)
            actualizarFotoProyecto(c)
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <Grid container spacing={4}>
                <Grid item xs={12} md={8}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        name="imgProyecto"
                        label="Imagenes de proyecto"
                        type="file"
                        ref={inputRef}
                        id="imgProyecto"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={imgProyectoHandler}
                    />
                    { imgProyectoError && <Typography color="error">
                        {imgProyectoError}
                    </Typography>}
                </Grid>
                <Grid item xs={12} md={4}>
                    <Button variant="outlined" fullWidth onClick={ agregarImgProyecto }>
                        Agregar
                    </Button>
                </Grid>

                <Grid xs={12} item container>
                    {proyectoLink && proyectoLink.map((data) => {
                        return (
                            <Card key={data.key} >
                                <CardMedia
                                className={classes.media}
                                image={data.url}
                                title={data.url}
                                />
                                <CardActions>
                                    <Button size="small" color="primary" onClick={ ()=> handleDelete(data) }>
                                        Borrar
                                    </Button>
                                </CardActions>
                            </Card>
                        );
                    })}
                </Grid>

            </Grid>
        </>
    )
}

export default ImgProyecto