//react
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
// material-ui
import { Container, Typography, Grid } from '@material-ui/core';
//componets
import Header from '../components/Header';
import SeccionVideo from '../components/inicio/SeccionVideo';
import SeccionProyecto from '../components/inicio/SeccionProyecto';
import SeccionRegalos from '../components/inicio/SeccionRegalos';
import Footer from '../components/Footer';
//styles
import { makeStyles } from '@material-ui/core/styles';
//firebase
import { functions } from '../firebase/firebaseConfig';

const useStyles = makeStyles((theme) => ({
	hero: (props) => ({
		backgroundImage: `url(${props.backgroundImage})`,
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center center',
		height: '400px',
		color: 'white',
	}),
	mensaje: {
		marginTop: theme.spacing(5),
		marginBottom: theme.spacing(5),
	},
}));

const EventoPage = () => {
	const { id } = useParams();

	const verMesaEvento = functions.httpsCallable('verMesaEvento');

	const [anfitrionUno, setAnfitrionUno] = useState('');
	const [anfitrionDos, setAnfitrionDos] = useState('');
	const [fecha, setFecha] = useState('');
	const [lugar, setLugar] = useState('');
	const [tipoEvento, setTipoEvento] = useState('');
	const [mensaje, setMensaje] = useState('');
	const [video, setVideo] = useState('');
	const [imgProyecto, setImgProyecto] = useState([]);
	const [zonas, setZonas] = useState([]);
	const [portada, setPortada] = useState('');
	const [email, setEmail] = useState('');

	useEffect(() => {
		//getMesaInfo(id)

		verMesaEvento({ id })
			.then((result) => {
				setAnfitrionUno(result.data.anfitrionUno);
				setAnfitrionDos(result.data.anfitrionDos);
				setTipoEvento(result.data.tipoEvento);
				setFecha(result.data.fecha);
				setLugar(result.data.lugar);
				setMensaje(result.data.mensaje);
				setPortada(result.data.imgPortada);
				setEmail(result.data.email);
				setVideo(result.data.video);
				setZonas(result.data.zonas);
				setImgProyecto(result.data.imgProyecto);
			})
			.catch((error) => {
				console.log(error.code, error.message);
			});
	}, [id]);

	let props = { backgroundImage: portada };

	const classes = useStyles(props);

	return (
		<>
			<Container>
				<Header />
			</Container>

			<Grid
				container
				className={classes.hero}
				direction='column'
				justify='center'
				alignItems='center'
			>
				<Grid item>
					<Typography variant={'h2'} align={'center'} gutterBottom>
						{anfitrionUno} & {anfitrionDos}
					</Typography>
					<Typography variant={'h6'} align={'center'} gutterBottom>
						{tipoEvento}
					</Typography>
					<Typography variant={'h6'} align={'center'} gutterBottom>
						{fecha}
					</Typography>
					<Typography variant={'h6'} align={'center'} gutterBottom>
						{lugar}
					</Typography>
				</Grid>
			</Grid>

			<Container maxWidth='md' className={classes.mensaje}>
				<Grid container justify='center'>
					<Grid item xs={12} md={2}></Grid>
					<Grid
						item
						container
						justify='center'
						xs={12}
						md={8}
						className={classes.mensaje}
					>
						<Typography variant={'h6'} align={'center'} gutterBottom>
							{mensaje}
						</Typography>
					</Grid>
					<Grid item xs={12} md={2}></Grid>
				</Grid>

				<SeccionVideo video={video} />

				<SeccionProyecto imgProyecto={imgProyecto} />

				<SeccionRegalos
					{...{ zonas, tipoEvento, anfitrionUno, anfitrionDos, email }}
				/>
			</Container>

			<Footer />
		</>
	);
};

export default EventoPage;
