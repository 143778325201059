//react
import React, { useState, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { useHistory, useLocation, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types';
//material-ui
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import HomeIcon from '@material-ui/icons/Home';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import SettingsIcon from '@material-ui/icons/Settings';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
//styles
import { makeStyles, useTheme } from '@material-ui/core/styles';
//firebase
import { auth } from '../firebase/firebaseConfig'

const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
        }
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        boxShadow: 'none',
        BorderBottom: 'solid 1px #CFC1B5'

        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
        display: 'none',
        },
    },
    active: {
        background: '#CFC1B5'
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,

    drawerPaper: {
        width: drawerWidth,
        backgroundColor: '#FCF9F3'
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    imgBar: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}));

const Layout = (props) => {

    const { user, admin } = useContext(AuthContext);

    const { window, children } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = useState(false);
    const history = useHistory();
    const location = useLocation();

    const menuItemsAdmin = [
        { 
            text: 'Home Admin', 
            icon: <HomeIcon />, 
            path: '/admin' 
        },
        { 
            text: 'Mesas de Regalos', 
            icon: <CardGiftcardIcon />, 
            path: '/mesas-regalos' 
        },
        { 
            text: 'Crear Mesa de Regalos', 
            icon: <LibraryAddIcon />, 
            path: '/crear-mesa' 
        }
    ];

    const menuItems = [
        { 
            text: 'Home', 
            icon: <HomeIcon />, 
            path: '/user' 
        },
        { 
            text: 'Regalos', 
            icon: <CardGiftcardIcon />, 
            path: '/regalos' 
        },
        { 
            text: 'Ajustes', 
            icon: <SettingsIcon />, 
            path: '/ajustes' 
        },
    ];

    //console.log(props);

    const onSignout = async () => {
        await auth.signOut()
        history.push('/')
    }

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div>
            <Grid container className={classes.toolbar}>
                <Grid className={classes.imgBar}>
                    <img src='/images/logoMenu.png' width="80%" alt='logo' onClick={ () => history.push('/')}/>
                </Grid>
            </Grid>
            <Divider />
            <List>
                { 
                    admin ? (menuItemsAdmin.map((item) => (
                        <ListItem 
                            button 
                            key={item.text}
                            onClick={ () => history.push(item.path) }
                            className={ location.pathname === item.path ? classes.active : null }
                        >
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.text} />
                    </ListItem>
                    ))) : (menuItems.map((item) => (
                        <ListItem 
                            button 
                            key={item.text}
                            onClick={ () => history.push(item.path) }
                            className={ location.pathname === item.path ? classes.active : null }
                        >
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.text} />
                    </ListItem>
                    )))
                }
            </List>
        </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <>
            { !user ? (
                <>
                    <h1>loading...</h1>
                    <Redirect to={{ pathname: '/'}} />
                </>
                
            ) : (
                <div className={classes.root}>
                    <CssBaseline />
                    <AppBar position="fixed" className={classes.appBar} color="default">
                        <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            className={classes.menuButton}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Grid container justify='space-between'>
                            <Typography display='inline' variant="h6" noWrap>
                                
                            </Typography>
                            <Button onClick={onSignout}>
                                Cerrar sesión
                            </Button>
                        </Grid>
                        </Toolbar>
                    </AppBar>
                    <nav className={classes.drawer} aria-label="mailbox folders">
                        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                        <Hidden smUp implementation="css">
                        <Drawer
                            container={container}
                            variant="temporary"
                            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            classes={{
                            paper: classes.drawerPaper,
                            }}
                            ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                            }}
                        >
                            {drawer}
                        </Drawer>
                        </Hidden>
                        <Hidden xsDown implementation="css">
                        <Drawer
                            classes={{
                            paper: classes.drawerPaper,
                            }}
                            variant="permanent"
                            open
                        >
                            {drawer}
                        </Drawer>
                        </Hidden>
                    </nav>
                    <main className={classes.content}>
                        <div className={classes.toolbar} />
                        { children }
                    </main>
                </div>
            )}
        </>
        
    );
}

Layout.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default Layout;