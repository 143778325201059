//react
import React, {useState} from 'react'
//material-ui
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Grid, Typography } from '@material-ui/core';
//styles
import { makeStyles } from '@material-ui/core/styles';
//firebase 
import { db, storage } from '../../firebase/firebaseConfig'

const useStyles = makeStyles((theme) => ({
    imgPortada: {
        width: '25%',
        maxWidth: '25%',
        marginTop: theme.spacing(1),
    }
}));

const ImgPortada = ({ mesaID, portadaLink, setPortadaLink }) => {

    const [imgPortada, setImgPortada] = useState(null)
    const [imgPortadaError, setImgPortadaError] = useState(null)

    const classes = useStyles();

    const types = ['image/png', 'image/jpeg'];

    const imgPortadaHandler = (e) => {
        let selectedImg = e.target.files[0]

        if(selectedImg && types.includes(selectedImg.type)) {
            setImgPortada(selectedImg)
            setImgPortadaError('')
        } else {
            setImgPortada(null)
            setImgPortadaError('Solo imagenes png / jpg')
        }
    }

    const actualizarFotoPortada = async (imgPortadaUrl) => {
        try {
            await db.collection('mesas').doc(mesaID).update({
                imgPortada: imgPortadaUrl
            })
            setPortadaLink(imgPortadaUrl)
        } catch (error) {
            console.error(error)
        }
    }

    const agregarImgPortada = () => {

        if(imgPortada) {
            const imgPortadaRef = storage.ref(`${mesaID}/portada/${imgPortada.name}`)
            imgPortadaRef.put(imgPortada).on('state_changed', snapshot => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log(progress);
            }, err => {
                setImgPortadaError(err)
            }, async () => {
                try {
                    const imgPortadaUrl = await imgPortadaRef.getDownloadURL();
                    //console.log(imgPortadaUrl);
                    actualizarFotoPortada(imgPortadaUrl);
                } catch (error) {
                    console.log(error);
                }
            })
        } else {
            setImgPortadaError('Ningun archivo selecionado')
        }
        
    }

    return (
        <>
            <Grid container spacing={4}>
                <Grid item xs={12} md={8}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        name="imgPortada"
                        label="Imagen de portada"
                        type="file"
                        id="imgPortada"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={imgPortadaHandler}
                        
                    />
                    { imgPortadaError && <Typography color="error">
                        {imgPortadaError}
                    </Typography>}
                </Grid>
                <Grid item xs={12} md={4}>
                    <Button variant="outlined" fullWidth onClick={agregarImgPortada}>
                        Agregar
                    </Button>
                </Grid>
            
                <Grid item xs={12} >
                    <img src={portadaLink} alt="portada" className={classes.imgPortada}/>
                </Grid>
            </Grid>
        </>
    )
}

export default ImgPortada
