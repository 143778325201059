//react
import React from 'react'
//material-ui
import { Typography, Grid } from '@material-ui/core'
//styles
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    hero: props => ({
        backgroundImage: `url(${props.backgroundImage})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        height: '400px',
        color: 'white',
    }),
    mensaje: {
        margin: theme.spacing(4),
    }
}))

const PortadaUser = ({mesa}) => {

    //const [info, setInfo] = useState({img: {portada: ''}})

    let props = {}

    if(mesa.imgPortada) {
        props = { backgroundImage: mesa.imgPortada }
    } else {
        props = { backgroundImage: '' }
    }

    const classes = useStyles(props);


    
    return (
        <>
            <Grid container className={classes.hero} direction="column" justify="center" alignItems="center">
                <Typography variant='h3' align='center'>
                    {mesa.anfitrionUno} & {mesa.anfitrionDos}
                </Typography>
                <Typography variant='h5' align='center'>
                    {mesa.tipoEvento}
                </Typography>
                <Typography variant='h5' align='center'>
                    {mesa.fecha}
                </Typography>
                <Typography variant='h5' align='center'>
                    {mesa.lugar} 
                </Typography>
            </Grid>
        </>
    )
}

export default PortadaUser
