//react
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
//pages
//...MainPages
import HomePage from './pages/HomePage'
import EventoPage from './pages/EventoPage'
import AportarPage from './pages/AportarPage'
import LoginPage from './pages/LoginPage'
import RecuperarPassPage from './pages/RecuperarPassPage'
import Layout from './pages/Layout'
//...AdminPages
import AdminPage from './pages/AdminPage'
import MesasRegalosAdminPage from './pages/MesasRegalosAdminPage'
import CrearMesaRegalosAdmin from './pages/CrearMesaRegalosAdmin'
import EditarMesaRegalosAdmin from './pages/EditarMesaRegalosAdmin'
import RegalosPageAdmin from './pages/RegalosPageAdmin'
import AjustesAdmin from './pages/AjustesAdmin'
//...UserPages
import UserPage from './pages/UserPage'
import RegalosPageUser from './pages/RegalosPageUser'
import AjustesUser from './pages/AjustesUser'
//...404
import NotFoundPage from './pages/NotFoundPage'
//material-ui
import { ThemeProvider } from '@material-ui/core'
import { theme } from './theme/theme'
//fireabase
import AuthContextProvider from './contexts/AuthContext'
import MesaUserContextProvider from './contexts/MesaUserContext'
import PreferenceIdContextProvider from './contexts/PreferenceIdContext'
//bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
    return (
        <>  
            <AuthContextProvider>
                <MesaUserContextProvider>
                    <ThemeProvider theme={theme}>
                        <PreferenceIdContextProvider>
                            <Router> 
                                <Switch>
                                    <Route path="/" component={HomePage} exact />
                                    <Route path="/login" component={LoginPage} />
                                    <Route path="/recuperar-pass" component={RecuperarPassPage} />
                                    <Route path="/eventos/:id" component={EventoPage} />
                                    <Route path="/aportar/:id" component={AportarPage} />
                                    <Layout>
                                        <Route exact path="/admin" component={AdminPage} />
                                        <Route exact path="/mesas-regalos" component={MesasRegalosAdminPage} />
                                        <Route exact path="/crear-mesa" component={CrearMesaRegalosAdmin} />
                                        <Route exact path="/editar-mesa" component={EditarMesaRegalosAdmin} />
                                        <Route exact path="/detalles-mesa" component={RegalosPageAdmin} />
                                        <Route exact path="/ajustes-admin" component={AjustesAdmin} />
                                        <Route exact path="/user" component={UserPage} />
                                        <Route exact path="/regalos" component={RegalosPageUser} />
                                        <Route exact path="/ajustes" component={AjustesUser} />
                                    </Layout>
                                    <Route path='*' component={NotFoundPage} />
                                </Switch>
                            </Router>
                        </PreferenceIdContextProvider>
                    </ThemeProvider>
                </MesaUserContextProvider>
            </AuthContextProvider>
        </>
    );
}

export default App