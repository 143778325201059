//react
import React from 'react'
import { Link } from "react-router-dom";
//material-ui
import { Typography, Avatar, Card, Button, Grid } from '@material-ui/core'
//styles
//import useStyles from '../../theme/styles';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    avatarlarge :{
        width: '96px',
        height: '96px',
        margin: theme.spacing(1)
    },
    btnVistiar : {
        color: '#000000',
        textDecoration: 'none',
        '&:hover': {
            color: '#000000',
            textDecoration: 'none',
        }
    },
    listaCard: {
        margin: theme.spacing(2,0,4,0),
        boxShadow: theme.shadows[1]
    }
}))

const ListaMesas = ({ mesas }) => {
    const classes = useStyles()

    return (
        <>
            {mesas && mesas.map((mesa) => (
                <Card className={classes.listaCard} key={mesa.id}>
                    <Grid container>
                        <Grid item xs={4} sm={2}>
                            <Avatar alt={mesa.anfitrion} src={mesa.imgPortada} className={classes.avatarlarge}/>
                        </Grid>
                        <Grid item xs={8} sm={7} container direction='column' justify='center'>
                            <Typography variant="h5">
                                {mesa.anfitrionUno} & {mesa.anfitrionDos}
                            </Typography>
                            <Typography>
                                {mesa.tipoEvento}
                            </Typography>
                            <Typography>
                                {mesa.fecha}
                            </Typography>
                            <Typography>
                                {mesa.lugar}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3} container alignItems='center' justify='center'>
                            <Button size="large" color="primary" variant='contained'>
                                <Link to={`/eventos/${mesa.id}`} className={classes.btnVistiar}>
                                    Visitar Mesa
                                </Link>
                            </Button>
                        </Grid>
                    </Grid>
                </Card>
            ))}
        </>
    )
}

export default ListaMesas