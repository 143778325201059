import { createMuiTheme } from '@material-ui/core'

export const theme = createMuiTheme({
    palette: {
        primary: {
        main: '#CFC1B5'
        }
    },
    typography: {
        fontFamily: 'Lora',
        body1:{
            fontFamily: 'Raleway'
        },
        body2:{
            fontFamily: 'Raleway'
        }
    },
    shape: {
        borderRadius: 0
    }
})