//react
import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { useHistory } from 'react-router-dom'
//material-ui
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ImgPortadaUser from '../components/user/imgPortadaUser';
//firebase
import { db } from '../firebase/firebaseConfig'

const useStyles = makeStyles((theme) => ({
    formControl: {
        width: '100%',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const AjustesUser = () => {

    const { user } = useContext(AuthContext);

    const classes = useStyles();

    const history = useHistory();
    
    const [anfitrionUno, setAnfitrionUno] = useState('')
    const [anfitrionDos, setAnfitrionDos] = useState('')
    const [fecha, setFecha] = useState('')
    const [lugar, setLugar] = useState('')
    const [tipoEvento, setTipoEvento] = useState('')
    const [mensaje, setMensaje] = useState('')
    const [portada, setPortada] = useState('')

    //Portada
    const [imgPortada, setImgPortada] = useState(null)
    const [imgPortadaError, setImgPortadaError] = useState(null)


    const getMesa = async (mesaID) => {
        try {
            const data = await db.collection('mesas').doc(mesaID).get()
        
            const info = {...data.data()}

            setAnfitrionUno(info.anfitrionUno)
            setAnfitrionDos(info.anfitrionDos)
            setTipoEvento(info.tipoEvento)
            setFecha(info.fecha)
            setLugar(info.lugar)
            setMensaje(info.mensaje)
            setPortada(info.imgPortada)
        } catch (error) {
            console.log(error);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await db.collection('mesas').doc(user.uid).update({
                anfitrionUno: anfitrionUno,
                anfitrionDos: anfitrionDos,
                tipoEvento: tipoEvento,
                fecha: fecha,
                lugar: lugar,
                mensaje: mensaje,
            })
            history.push('/user')

        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        getMesa(user.uid);
    }, [user])


    return (
        <Container maxWidth="lg">
            <Typography variant="h6" gutterBottom>
                Ajustes
            </Typography>
            <form className={classes.form} onSubmit={handleSubmit}>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="anfitrionUno"
                            label="Anfitrión"
                            type="text"
                            id="anfitrionUno"
                            autoComplete="off"
                            value={anfitrionUno}
                            onChange={e => setAnfitrionUno(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="anfitrionDos"
                            label="Pareja"
                            type="text"
                            id="anfitrionDos"
                            autoComplete="off"
                            value={anfitrionDos}
                            onChange={e => setAnfitrionDos(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControl required variant="outlined" className={classes.formControl}>
                            <InputLabel id="tipoEvento-label">Tipo de Evento</InputLabel>
                            <Select
                                labelId="tipoEvento-label"
                                id="tipoEvento"
                                value={tipoEvento}
                                onChange={e => setTipoEvento(e.target.value)}
                                label="Tipo de Evento"
                                className={classes.selectEmpty}
                            >
                                <MenuItem value="Boda">Boda</MenuItem>
                                <MenuItem value="Bautizo">Bautizo</MenuItem>
                                <MenuItem value="PrimeraComunion">Primera comunión</MenuItem>
                                <MenuItem value="OpenHouse">Open House</MenuItem>
                                <MenuItem value="Cumple">Cumpleaños</MenuItem>
                                <MenuItem value="Otro">Otro</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="lugarEvento"
                            label="Lugar del Evento"
                            type="text"
                            id="lugarEvento"
                            autoComplete="off"
                            value={lugar}
                            onChange={e => setLugar(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            id="date"
                            required
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            label="Fecha del evento"
                            type="date"
                            value={fecha}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={e => setFecha(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} >
                        <TextField 
                            id="state" 
                            variant="outlined"
                            name="state" 
                            label="Mensaje de bienvenida o agradecimiento" 
                            fullWidth 
                            multiline
                            rows={6}
                            value={mensaje}
                            onChange={e => setMensaje(e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12}>

                        <Typography gutterBottom variant="h6">
                                Imágen de portada 
                        </Typography>

                        <ImgPortadaUser {...{ user, imgPortada, setImgPortada, imgPortadaError, setImgPortadaError, portada, setPortada }} />

                    </Grid>

                    <Grid item xs={12}>
                        <Button type="submit" variant="contained" color="primary" >Guardar</Button>
                    </Grid>
                    
                </Grid>
            </form>                
        </Container>
    )
}

export default AjustesUser
