//react
import React, { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { useHistory, Link } from 'react-router-dom'
//material-ui
import {  Button, Grid } from '@material-ui/core'
import Divider from '@material-ui/core/Divider';
import PinterestIcon from '@material-ui/icons/Pinterest';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
//styles
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    header: {
        padding: theme.spacing(4, 0, 4, 0)
    },
    logo: {
        width: '80%'
    },
    social :{
        color: '#CFC1B5'
    },
}));


const Header = () => {
    const classes = useStyles()

    const history = useHistory();

    const { user } = useContext(AuthContext);

    return (
        <>
            <Grid container className={classes.header}>
                <Grid item xs={6} md={3}>
                    <Link to={{ pathname: "https://studiomaisonco.com/" }} target="_blank" >
                        <img src="/images/logoMenu.png" alt="logo" className={classes.logo}/>
                    </Link>
                </Grid>
                <Grid item container xs={6} md={9}  justify='center' direction="column" alignItems="flex-end">
                    <Grid>
                        <Link to={{ pathname: "https://www.facebook.com/studio.maison.co" }} target="_blank">
                            <FacebookIcon fontSize="small" className={classes.social}/>
                        </Link>
                        <Link to={{ pathname: "https://www.pinterest.com.mx/studiomaisonco/" }} target="_blank">
                            <PinterestIcon fontSize="small" className={classes.social}/>
                        </Link>
                        <Link to={{ pathname: "https://www.instagram.com/studio.maison.co/" }} target="_blank">
                            <InstagramIcon fontSize="small" className={classes.social}/>
                        </Link>
                    </Grid>
                    <Divider />
                    <Grid>
                        <Button onClick={ () => history.push('/login') } >
                            {user ? 'Ver cuenta' : 'Iniciar sesión'}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default Header
