//react
import React, {  useState, useEffect, createContext } from "react";
//firebase
import { auth } from "../firebase/firebaseConfig";

export const AuthContext = createContext();

const AuthContextProvider = ({ children }) => {
    const [user, setUser] = useState(null)
    const [admin, setAdmin] = useState(false)

    const checkAdmin = async () => {
        const token = await auth.currentUser.getIdTokenResult()
        if(token.claims.admin) {
            setAdmin(true)
        } else {
            setAdmin(false)
        }
    }

    useEffect(() => {
        //console.log(firebase.auth())
        auth.onAuthStateChanged(setUser)
        if (user) {
            checkAdmin()
        }

    }, [user]);

    return (
        <AuthContext.Provider value={{ user, admin }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContextProvider