//react
import React, {  useState, createContext } from "react";

export const PreferenceIdContext = createContext();

const PreferenceIdContextProvider = ({ children }) => {
    
    const [monto, setMonto] = useState(500)
    const [zonassss, setZonassss] = useState('')
    const [padrinos, setPadrinos] = useState('')
    const [mensajeFelicitacion, setMensajeFelicitacion] = useState('')
    const [preferenceId, setPreferenceId] = useState('');

    return (
        <PreferenceIdContext.Provider value={{ preferenceId, setPreferenceId, monto, setMonto, zonassss, setZonassss, padrinos, setPadrinos, mensajeFelicitacion, setMensajeFelicitacion }}>
            {children}
        </PreferenceIdContext.Provider>
    )
}

export default PreferenceIdContextProvider;