//react
import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { Link, useHistory } from 'react-router-dom';
//material-ui
import { Container, Typography, CssBaseline, Button, TextField, Grid, Box } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
//components
import Header from '../components/Header';
//styles
import { makeStyles } from '@material-ui/core/styles';
//firebase
import { auth } from '../firebase/firebaseConfig'

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
        {'Derechos reservados © '}
            Studio Maison
        {' '}
        {new Date().getFullYear()}
        {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    link :{
        textDecoration: 'none',
        color: '#CFC1B5'
    }
}));

const LoginPage = ({histor}) => {

    const classes = useStyles();
    const history = useHistory();
    
    const { user } = useContext(AuthContext);

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [checked, setChecked] = useState(false);
    const [error, setError] = useState('')

    const checkAdmin = async () => {
        try {
            const token = await auth.currentUser.getIdTokenResult()
            if(token.claims.admin) {
                history.push('/admin')
            } else {
                history.push('/user')
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (user) {
            checkAdmin()
        }
    }, [user])

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        await auth.signInWithEmailAndPassword(email, password)
            .catch(err => setError(err.message))
        checkAdmin()
    }

    return (
        <>
            <Container>
                <Header />
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <div className={classes.paper}>
                        <Typography component="h1" variant="h5">
                            Iniciar sesión
                        </Typography>
                        <form className={classes.form} onSubmit={handleSubmit}>
                            
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Correo electrónico"
                                name="email"
                                autoComplete="off"
                                autoFocus
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Contraseña"
                                type="password"
                                id="password"
                                autoComplete="off"
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                            />
                            <Checkbox
                                checked={checked}
                                onChange={handleChange}
                                name="checked"
                                color="primary"
                                required
                            />
                            <Link 
                                to={{ pathname: "https://studiomaisonco.com/terminos-y-condiciones/" }} 
                                target="_blank"
                                className={classes.link}
                            >
                                <Typography variant="body1" display="inline" >
                                    Acepto términos y condiciones
                                </Typography>
                                
                            </Link>    
                            <Typography align="center" component="h1" variant="h5" color="error">
                                {error}
                            </Typography>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                            >
                                Iniciar Sesión
                            </Button>
                            <Grid container>
                                <Grid item xs>
                                <Link to="/recuperar-pass" variant="body1" className={classes.link}>
                                    ¿Olvidaste tu contraseña?
                                </Link>
                                </Grid>
                            </Grid>
                            
                        </form>
                    </div>
                    <Box mt={8}>
                        <Copyright />
                    </Box>
                </Container>
            </Container>
        </>
    )
}

export default LoginPage