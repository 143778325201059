// react
import React from 'react'
import ReactPlayer from 'react-player/youtube'
// material-ui
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';



const useStyles = makeStyles((theme) => ({
    videoContainer: {
        marginTop:theme.spacing(4),
        marginBottom: theme.spacing(4)
    }
}));

const SeccionVideo = ({video}) => {

    const classes = useStyles();

    return (
        <>
            <Grid className={classes.videoContainer} container justify="center">
                <ReactPlayer url={video} light width='100%' height='513px'/>
            </Grid>
        </>
    )
}

export default SeccionVideo