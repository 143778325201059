// react
import React from 'react'
// material-ui
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// react-bootstrap
import Carousel from 'react-bootstrap/Carousel'


const useStyles = makeStyles((theme) => ({
    proyectoContainer :{
        marginTop:theme.spacing(4),
        marginBottom: theme.spacing(4)
    },
    titulo: {
        marginBottom: theme.spacing(4)
    }
}));

const SeccionProyecto = ({imgProyecto}) => {

    const classes = useStyles();

    return (

        <>
            <Grid className={classes.proyectoContainer}>
                <Typography variant="h6" gutterBottom align='center' className={classes.titulo}>
                    Proyecto: 
                </Typography>
                <Carousel fade>
                { imgProyecto  && imgProyecto.map(imagen => (
                    <Carousel.Item key={imagen.key}>
                        <img
                            className="d-block w-100"
                            src={imagen.url}
                            alt={imagen.url}
                        />
                    </Carousel.Item>
                ))}
                </Carousel>
            </Grid>
        </>
    )
}

export default SeccionProyecto