//react
import React, { useState, useEffect, useContext } from 'react'
import { useParams, useHistory } from "react-router-dom";
// context PreferenceId
import { PreferenceIdContext } from '../contexts/PreferenceIdContext'
// material-ui
import { Container, Typography, Grid, Button } from '@material-ui/core';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import Divider from '@material-ui/core/Divider';
//componets
import Header from '../components/Header';
import Footer from '../components/Footer';
//styles
import { makeStyles } from '@material-ui/core/styles';
//firebase
import { db, functions } from '../firebase/firebaseConfig'


const useStyles = makeStyles((theme) => ({
    hero: props => ({
        backgroundImage: `url(${props.backgroundImage})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        height: '400px',
        color: 'white',
    }),
    mensaje: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
    },
    cardBody: {
        marginTop: theme.spacing(4),
        width: '100%'
    },
    divBtnAportar: {
        display: 'flex',
        justifyContent: 'center'
    }
}))

const AportarPage = () => {
    const { id } = useParams();

    const verMesaAportar = functions.httpsCallable('verMesaAportar');

    const history = useHistory();

    //context PreferenceId
    const { preferenceId, monto, zonassss, padrinos, mensajeFelicitacion } = useContext(PreferenceIdContext);

    

    const [anfitrionUno, setAnfitrionUno] = useState('')
    const [anfitrionDos, setAnfitrionDos] = useState('')
    const [fecha, setFecha] = useState('')
    const [lugar, setLugar] = useState('')
    const [tipoEvento, setTipoEvento] = useState('')
    const [portada, setPortada] = useState('')

    // const getMesaInfo = async (idMesa) => {
    //     try {
    //         const data = await db.collection('mesas').doc(idMesa).get()
            
    //         const info = {...data.data()}
            
    //         setAnfitrionUno(info.anfitrionUno)
    //         setAnfitrionDos(info.anfitrionDos)
    //         setTipoEvento(info.tipoEvento)
    //         setFecha(info.fecha)
    //         setLugar(info.lugar)
    //         setPortada(info.imgPortada)

    //         let arr = [...info.padrinos]
    //         arr.push({
    //             preferenceId: preferenceId,
    //             padrinos: padrinos,
    //             mensajeFelicitacion: mensajeFelicitacion,
    //             monto: monto,
    //             zona: zonassss,
    //         })

    //         await db.collection('mesas').doc(idMesa).update({
    //             padrinos: arr
    //         })

    //     } catch (error) {
    //         console.log(error);
    //     }
    // }


    useEffect(() => {

        console.log(zonassss);
        verMesaAportar({

            id:id,
            preferenceId: preferenceId,
            padrinos: padrinos,
            mensajeFelicitacion: mensajeFelicitacion,
            monto: monto,
            zona: zonassss,

        }).then(result => {

            setAnfitrionUno(result.data.anfitrionUno)
            setAnfitrionDos(result.data.anfitrionDos)
            setTipoEvento(result.data.tipoEvento)
            setFecha(result.data.fecha)
            setLugar(result.data.lugar)
            setPortada(result.data.imgPortada)

        }).catch(error => {
            console.log(error.code, error.message)
        });

        if(preferenceId) {
            const script = document.createElement("script");
            script.type = 'text/javascript'
            script.src = 'https://www.mercadopago.com.mx/integrations/v1/web-payment-checkout.js'
            script.setAttribute('data-preference-id', preferenceId)
            script.setAttribute('data-button-label','DAR APORTACIÓN')
            const divBtnAportar = document.getElementById('divAportar');
            divBtnAportar.appendChild(script);
        }
    }, [preferenceId, id])
    
    const handleBack = () => {
        history.push(`/eventos/${id}`)
    }

    let props = { backgroundImage: portada }
    
    const classes = useStyles(props);

    return (
        <>
            <Container>
                <Header />
            </Container>
            
            <Grid container className={classes.hero} direction="column" justify="center" alignItems="center">
                <Grid item>
                    <Typography variant={'h2'} align={'center'} gutterBottom>
                        {anfitrionUno} & {anfitrionDos}
                    </Typography>
                    <Typography variant={'h6'} align={'center'} gutterBottom>
                        {tipoEvento}
                    </Typography>
                    <Typography variant={'h6'} align={'center'} gutterBottom>
                        {fecha}
                    </Typography>
                    <Typography variant={'h6'} align={'center'} gutterBottom>
                        {lugar}
                    </Typography>
                </Grid>
            </Grid>

            <Container component='main' maxWidth='sm'>
                <Grid container justify='center'>
                    <Card className={classes.cardBody}>
                        
                            <CardContent>
                                <Grid container justify='center'>
                                    <CardGiftcardIcon fontSize='large' color='primary'/>
                                </Grid>
                                <Typography variant="h5" gutterBottom align='center'>
                                    Resumen
                                </Typography>
                                <Typography variant="h6" gutterBottom>
                                    Aportación para: 
                                </Typography>
                                <Typography gutterBottom>
                                    {zonassss}
                                </Typography>

                                <Divider />

                                <Typography variant="h6" gutterBottom>
                                    Monto de la aportación: 
                                </Typography>
                                <Typography gutterBottom>
                                    $ {monto} MXN + comisión pasarela de pago $ {(((monto+4*1.16)/(1-(0.0349*1.16)))-monto).toFixed(2)} MXN
                                </Typography>

                                <Divider />

                                <Typography variant="h6" gutterBottom>
                                    Quién / Quienes regalan: 
                                </Typography>
                                <Typography gutterBottom>
                                    {padrinos}
                                </Typography>

                                <Divider />

                                <Typography variant="h6" gutterBottom>
                                    Mensaje de felicitación: 
                                </Typography>
                                <Typography gutterBottom>
                                    {mensajeFelicitacion}
                                </Typography>

                                <Divider />

                            </CardContent>
                        
                        <CardActions>
                            <div id='divAportar' className='divBtnAportar'>

                            </div>
                        </CardActions>
                    </Card>
                    
                </Grid>

                <Grid>
                    <Button onClick={handleBack}>
                        anterior
                    </Button>
                </Grid>

            </Container>

            <Footer />
        </>
    )
}

export default AportarPage