//react
import React, { useState, useEffect } from 'react';
//material-ui
import { Container, Typography, Grid } from '@material-ui/core';
//components
import Header from '../components/Header';
import BuscarMesa from '../components/inicio/BuscarMesa';
import ListaMesas from '../components/inicio/ListaMesas';
import Footer from '../components/Footer';
//styles
import { makeStyles } from '@material-ui/core/styles';
// firebase
import { functions } from '../firebase/firebaseConfig';

const useStyles = makeStyles((theme) => ({
	buscar: {
		backgroundColor: '#FCF9F3',
		height: '320px',
	},
	formBuscarMesa: {
		padding: theme.spacing(8, 0, 4, 0),
		backgroundColor: '#FCF9F3',
	},
	mensaje: {
		margin: theme.spacing(4),
	},
}));

const HomePage = () => {
	const classes = useStyles();

	const [mesas, setMesas] = useState([]);

	const verMesaHome = functions.httpsCallable('verMesaHome');

	useEffect(() => {
		verMesaHome()
			.then((result) => {
				setMesas(result.data);
			})
			.catch((error) => {
				console.log(error.code, error.message);
			});
	}, []); //NOOO poner mesas jajaja bug infinito

	return (
		<>
			<Container>
				<Header />
			</Container>

			<Grid
				container
				className={classes.buscar}
				justify='center'
				direction='column'
			>
				<Typography variant={'h5'} align={'center'} gutterBottom>
					Encuentra una mesa de regalos
				</Typography>
				<Container>
					<BuscarMesa />
				</Container>
			</Grid>

			<Container component='main'>
				<ListaMesas mesas={mesas} />
			</Container>

			<Footer />
		</>
	);
};

export default HomePage;
