//react
import React, {  useState, createContext } from "react";

export const MesaUserContext = createContext();

const MesaUserContextProvider = ({ children }) => {
    
    const [mesaID, setMesaID] = useState('');

    return (
        <MesaUserContext.Provider value={{ mesaID, setMesaID }}>
            {children}
        </MesaUserContext.Provider>
    )
}

export default MesaUserContextProvider;