//react
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
//material-ui
import { Typography, Container, Button, Grid} from '@material-ui/core'
//components
import CardRegalosAdmin from '../components/admin/CardRegalosAdmin'
//firebase
import { db, functions } from '../firebase/firebaseConfig'

const MesasRegalosAdminPage = () => {

    const [mesas, setMesas] = useState([])

    const history = useHistory();
    
    //firebase function
    const deleteUser = functions.httpsCallable('deleteUser');

    const getMesas = async () => {
        try {
            db.collection("mesas").orderBy('fecha', 'asc').onSnapshot((querySnapshot) => {
                const docs = [];
                querySnapshot.forEach((doc) => {
                    docs.push({ ...doc.data(), id: doc.id });
                });
                setMesas(docs);
            });
        } catch (error) {
            console.log(error);
        }
        
    };

    const onDeleteLink = async (id) => {
        if (window.confirm("Estas segur@ de borrar esta mesa? Esta acción NO se puede deshacer")) {

            try {
                await db.collection("mesas").doc(id).delete();
                const res = await deleteUser({
                    id:id
                });
                console.log(res);
                console.log('borrado exitoso');
            } catch (error) {
                console.log(error);
            };
            
        }
    }
    
    useEffect(() => {
        getMesas()
    }, [])

    return (
        <>
            <Container maxWidth="lg">
                <Grid container justify="space-between">
                    <Grid item >
                        <Typography variant="h5" gutterBottom>
                            Mesas de regalos
                        </Typography>
                        
                    </Grid>
                    <Grid item>
                        <Button onClick={ () => history.push('/crear-mesa') }>
                            + Crear nueva mesa de regalos
                        </Button>
                    </Grid>
                </Grid>

                <CardRegalosAdmin {...{ mesas , onDeleteLink}} />

            </Container>
        </>
    )
}

export default MesasRegalosAdminPage