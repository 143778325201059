//react
import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
//material-ui
import { Container } from '@material-ui/core'
//components
import PortadaUser from '../components/user/PortadaUser'
import CardRegalosUser from '../components/user/CardRegalosUser'
//sytles
import { makeStyles } from '@material-ui/core/styles';
//firebase
import { db } from '../firebase/firebaseConfig'
import SeccionProyecto from '../components/inicio/SeccionProyecto';
import SeccionVideo from '../components/inicio/SeccionVideo';

const useStyles = makeStyles((theme) => ({
    card: {
        marginTop: theme.spacing(-6),
    }
}))

const UserPage = () => {

    const { user } = useContext(AuthContext);

    const [mesa, setMesa] = useState({img: {portada: ''}});

    const classes = useStyles();

    const getMesa = async (id) => {
        try {
            const result = await db.collection('mesas').doc(id).get()
            //console.log(result.data());
            if(result.data())
            { 
                setMesa(result.data())
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getMesa(user.uid);
    }, [])

    return (
        <>
            <PortadaUser mesa={mesa}/>
            <Container maxWidth='md' className={classes.card}>
                <CardRegalosUser mesa={mesa}/>
                
                <SeccionVideo video={mesa.video} />

                <SeccionProyecto imgProyecto={mesa.imgProyecto}/>

            </Container>
        </>
    )
}

export default UserPage
