import React from 'react'

const AjustesAdmin = () => {
    return (
        <div>
            Ajustes Admin
        </div>
    )
}

export default AjustesAdmin
