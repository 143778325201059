//react
import React, { useState, useEffect } from 'react'
//material-ui
import { Typography ,Container } from '@material-ui/core'
//components
import CardResumenAdmin from '../components/admin/CardResumenAdmin'
//firebase
import { db } from '../firebase/firebaseConfig'

const AdminPage = () => {

    const [numMesas, setNumMesas] = useState(0)
    const [total, setTotal] = useState(0)
    const [tipoMesa, setTipoMesa] = useState({
        Boda: 0,
        Bautizo: 0,
        PrimeraComunion: 0,
        OpenHouse: 0,
        Cumple: 0,
        Otro: 0
    })

    useEffect(() => {
        
        db.collection('mesas').where('activa','==',true).get()
        .then((querySnapshot) => {
            setNumMesas(querySnapshot.size);
            let money = 0
            let cBoda = 0
            let cBautizo = 0
            let cPrimeraComunion = 0
            let cOpenHouse = 0
            let cCumple = 0
            let cOtro = 0

            querySnapshot.forEach(doc => {
                money += doc.data().cantidad
                
                if(doc.data().tipoEvento === 'Boda') {
                    cBoda = cBoda + 1
                }
                if(doc.data().tipoEvento === 'Bautizo') {
                    cBautizo = cBautizo + 1
                }
                if(doc.data().tipoEvento === 'PrimeraComunion') {
                    cPrimeraComunion = cPrimeraComunion + 1
                }
                if(doc.data().tipoEvento === 'OpenHouse') {
                    cOpenHouse = cOpenHouse + 1
                }
                if(doc.data().tipoEvento === 'Cumple') {
                    cCumple = cCumple + 1
                }
                if(doc.data().tipoEvento === 'Otro') {
                    cOtro = cOtro + 1
                }
            })

            setTotal(money);
            setTipoMesa({ 
                Boda: cBoda,
                Bautizo: cBautizo,
                PrimeraComunion: cPrimeraComunion,
                OpenHouse: cOpenHouse,
                Cumple: cCumple,
                Otro: cOtro
            })
        }).catch(err => {
            console.log(err);
        })
    }, []) 

    return (
        <>
            <Container>
                <Typography variant="h4">
                    Bienvenidas Studio Maison Co
                </Typography>
                <CardResumenAdmin {...{ numMesas, total, tipoMesa }}/>
            </Container>
        </>
    )
}

export default AdminPage
