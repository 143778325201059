//react
import React, { useState } from 'react'
//material-ui
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
//styles
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(0.5),
        margin: 0,
    },
    chip: {
        margin: theme.spacing(0.5),
    },
}));

const ZonasMesas = ({ zonas, setZonas }) => {
    const classes = useStyles();

    //const [chipData, setChipData] = useState([]);

    const [newZone, setNewZone] = useState('')

    const handleDelete = (chipToDelete) => () => {
        setZonas((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
    };

    const handleAdd = () => {
        let a = [...zonas]
        a.push({key: Math.random()*1000, label: newZone })
        setZonas(a)
        setNewZone('')
    }

    return (
        <>
            <Grid container spacing={4}>
                <Grid item xs={12} md={8}>
                    <TextField 
                        id="zona" 
                        label="Agregar zonas" 
                        variant="outlined" 
                        fullWidth
                        name="zona"
                        value={newZone}
                        onChange={e => setNewZone(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Button variant="outlined" fullWidth onClick={handleAdd}>
                        Agregar
                    </Button>
                </Grid>
            
                <Grid item component="ul" className={classes.root}>
                    {zonas.map((data) => {
                        return (
                        <li key={data.key}>
                            <Chip
                                label={data.label}
                                onDelete={handleDelete(data)}
                                className={classes.chip}
                            />
                        </li>
                        );
                    })}
                </Grid>
            </Grid>
        </>
    );
}

export default ZonasMesas